import { render, staticRenderFns } from "./RSQPregnant.vue?vue&type=template&id=53f79779&"
import script from "./RSQPregnant.vue?vue&type=script&lang=js&"
export * from "./RSQPregnant.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports