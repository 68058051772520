<template>
  <table class="table table-striped">
    <tr
      :class="
        isAllTheme ? 'bg-dark text-white' : 'bg-purple-2-theme text-white'
      "
    >
      <th style="width: 900px" scope="col">3. ประเภทของการกระทำรุนแรง</th>
      <th scope="col">จำนวน (ราย)</th>
    </tr>
    <tbody>
      <tr>
        <th scope="col">3.1 ข้อมูลการกระทำความรุนแรง</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.violence_situation" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">3.1.1 สถานที่เกิดเหตุการณ์</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.event_place" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">3.2 ความเกี่ยวข้องกับผู้กระทำรุนแรง</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.doer_relation" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">3.3 ปัจจัยกระตุ้นให้เกิดการกระทำรุนแรง</th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">ผู้กระทำ</th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">สิ่งกระตุ้น</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.doerCauseList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">สัมพันธภาพในครอบครัว</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.doerFamilyList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">ปัญหาเศรฐกิจ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.doerFamilyList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">ปัญหาสุขภาพ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.doerHealthList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">ปัญหาสุขภาพจิต</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.doerMentalList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">ผู้ถูกกระทำ</th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">สิ่งกระตุ้น</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victimCauseList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">สัมพันธภาพในครอบครัว</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victimFamilyList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">ปัญหาเศรฐกิจ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victimEconomicList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">ปัญหาสุขภาพ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victimHealthList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">ปัญหาสุขภาพจิต</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victimMentalList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">3.4 ลักษณะการกระทำความรุนแรง </th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">3.4.1 การกระทำความรุนแรงทางร่างกาย</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victim_body_hurt" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.violenceType" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">3.4.2 การกระทำความรุนแรงทางเพศ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victim_rape" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.victim_rape_intention" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">พฤติกรรมการกระทำ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victimRapeAction" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.indecent" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>ลักษณะการขู่เข็ญ</td>
        <td></td>
      </tr>
      <tr>
        <td>{{ model.coerce[0].name }}</td>
        <td>{{ model.coerce[0].value }}</td>
      </tr>
      <tr>
        <td>{{ model.coerce[1].name }}</td>
        <td>{{ model.coerce[1].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.victimRapeForce" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.coerce[2].name }}</td>
        <td>{{ model.coerce[2].value }}</td>
      </tr>
      <tr>
        <td>{{ model.coerce[3].name }}</td>
        <td>{{ model.coerce[3].value }}</td>
      </tr>
      <tr>
        <th scope="col">3.4.3 การกระทำความรุนแรงทางจิตใจ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victim_mind_effect" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.mentalViolence" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">3.4.4 การละเลยทอดทิ้ง</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victim_neglect" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.abandon" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">3.4.5 การล่อลวง/บังคับแสวงหาประโยชน์ </th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victim_cozen" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.temptation" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["model", "isAllTheme"],
  data() {
    return {
      temptation: [
        { name: "แสวงหาผลประโยชน์โดยมิชอบ", key: "victim_cozen_force" },
        { name: "การบังคับใช้หรือบริการ", key: "victim_cozen_service" },
        { name: "การค้าประเวณี", key: "victim_cozen_sell" },
        { name: "แรงงานเด็กในรูปแบบที่เลวร้าย", key: "victim_cozen_child" },
        { name: "ค้ามนุษย์", key: "victim_cozen_trafficking" },
      ],
      abandon: [
        { name: "ละเลย", key: "victim_neglect_action" },
        { name: "ทอดทิ้ง", key: "victim_left" },
      ],
      mentalViolence: [
        { name: "กักขังหน่วงเหนี่ยว", key: "victim_cage" },
        { name: "ดูถูกเหยียดหยาม", key: "victim_hate_speech" },
        { name: "ข่มขู่", key: "victim_intimidate" },
        { name: "ทำให้หวาดกลัว", key: "victim_fear" },
        { name: "เฉยเมย ไม่สนใจ", key: "victim_ignore" },
        { name: "ทะเลาะ", key: "victim_quarrel" },
        { name: "ทำร้ายต่อหน้าเด็ก", key: "victim_hurt_on_child" },
      ],
      victimRapeForce: [
        { name: "ทำร้ายร่างกาย", key: "victim_rape_force_body" },
        { name: "ใช้สาร", key: "victim_rape_force_drug" },
        { name: "เหล้า", key: "victim_rape_force_alcohol" },
      ],
      coerce: [
        { name: "การขู่เข็ญด้วยประการใด", key: "victim_rape_coerce_desc" },
        { name: "การใช้กำลังประทุษร้ายด้วยการ", key: "victim_rape_force" },
        {
          name: "การทำให้อยู่ในภาวะที่ไม่สามารถขัดขืนได้",
          key: "victim_rape_restrain",
        },
        {
          name: "การทำให้เข้าใจผิดว่าผู้กระทำเป็นบุคคลอื่น ๆ",
          key: "victim_rape_fake",
        },
      ],
      indecent: [
        { name: "ให้แสดงหรือดูรูปภาพ", key: "victim_rape_content" },
        { name: "การเปิดของสงวน", key: "victim_rape_nude" },
        { name: "กอดจูบลูบคลำ", key: "victim_rape_touch" },
        { name: "อื่นๆ", key: "victim_rape_other" },
      ],
      victimRapeAction: [
        { name: "กระทำชำเรา", key: "victim_rape_hurt" },
        { name: "กระทำอนาจาร", key: "victim_rape_shy" },
      ],
      victim_cozen: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      victim_neglect: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      victim_mind_effect: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      victim_rape: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      victim_body_hurt: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      victim_rape_intention: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      violence_situation: [
        { name: "ถูกกระทำรุนแรงครั้งแรก", grid: "col-5" },
        { name: "เคยถูกกระทำรุนแรงมาก่อน", grid: "col-5" },
      ],
      doerCauseList: [
        { name: "สุรา", key: "doer_cause_alcohol" },
        { name: "สื่อลามก", key: "doer_cause_porn" },
        { name: "ยาบ้า", key: "doer_cause_amphetamine" },
        { name: "สารเสพติด", key: "doer_cause_drug" },
        { name: "สื่อออนไลน์", key: "doer_cause_online" },
        { name: "รายได้", key: "doer_cause_income" },
      ],
      victimCauseList: [
        { name: "สุรา", key: "doer_cause_alcohol" },
        { name: "สื่อลามก", key: "doer_cause_porn" },
        { name: "ยาบ้า", key: "doer_cause_amphetamine" },
        { name: "สารเสพติด", key: "doer_cause_drug" },
        { name: "สื่อออนไลน์", key: "doer_cause_online" },
        { name: "รายได้", key: "doer_cause_income" },
      ],
      doer_relation: [
        { name: "พ่อ" },
        { name: "แม่" },
        { name: "พี่น้อง" },
        { name: "ญาติ" },
        { name: "พ่อบุญธรรม" },
        { name: "แม่บุญธรรม" },
        { name: "พ่อเลี้ยง" },
        { name: "แม่เลี้ยง" },
        { name: "ลูกสาว" },
        { name: "ลูกชาย" },
        { name: "ลูกเลี้ยง" },
        { name: "ผู้ดูแล" },
        { name: "ผู้อุปการะ" },
        { name: "แฟน" },
        { name: "สามี" },
        { name: "ภรรยา" },
        { name: "คู่เพศทางเลือก" },
        { name: "เพื่อน" },
        { name: "เพื่อนร่วมงาน" },
        { name: "เพื่อนบ้าน" },
        { name: "ครู" },
        { name: "นายจ้าง" },
        { name: "ลูกจ้าง" },
        { name: "พระ" },
        { name: "นักบวช" },
        { name: "คนรู้จัก" },
        { name: "คนไม่รู้จัก" },
        { name: "คนแปลกหน้า" },
        { name: "หน่วยงานรัฐ" },
        { name: "หน่วยงานเอกชน+มูลนิธิ" },
      ],
      place: [
        { name: "บ้านพัก", key: "" },
        { name: "บ้านผู้กระทำ	", key: "" },
        { name: "บ้านคนอื่น", key: "" },
        { name: "หอพัก", key: "" },
        { name: "บ้านร้าง", key: "" },
        { name: "สวนสาธารณะ	", key: "" },
        { name: "สนามกีฬา", key: "" },
        { name: "ในป่า", key: "" },
        { name: "ที่เปลี่ยว", key: "" },
        { name: "ถนน", key: "" },
        { name: "ทางหลวง", key: "" },
        { name: "ห้างสรรพสินค้า", key: "" },
        { name: "สถานที่ทำงาน", key: "" },
        { name: "สถานที่เรียน", key: "" },
        { name: "โรงเรียน", key: "" },
        { name: "สถานรับเลี้ยงเด็ก", key: "" },
        { name: "สถานบันเทิง", key: "" },
        { name: "โรงแรม", key: "" },
        { name: "นา", key: "" },
        { name: "ไร่", key: "" },
        { name: "สวน", key: "" },
      ],
      victimFamilyList: [
        { name: "นอกใจ/หึงหวง", key: "doer_family_cheat" },
        { name: "ทะเลาะวิวาท", key: "doer_family_fight" },
        { name: "การใช้ความรุนแรงในครอบครัว", key: "doer_family_violence" },
      ],
      victimEconomicList: [
        { name: "ว่างงาน", key: "doer_economic_unemployed" },
        { name: "หนี้สิน", key: "doer_economic_debt" },
        { name: "ยากจน", key: "doer_economic_poor" },
        { name: "การพนัน", key: "doer_economic_gambling" },
      ],
      victimHealthList: [
        { name: "เจ็บป่วยเรื้อรัง", key: "doer_health_sick" },
        { name: "พิการ", key: "doer_health_disabled" },
      ],
      victimMentalList: [
        { name: "ป่วยเป็นโรคจิตเวช", key: "doer_mental_illness" },
        { name: "ปัญหาทางพฤติกรรม", key: "doer_mental_behavior" },
        { name: "ปัญหาด้านอารมณ์/จิตใจ", key: "doer_mental_mood_mind" },
      ],
      doerFamilyList: [
        { name: "นอกใจ/หึงหวง", key: "doer_family_cheat" },
        { name: "ทะเลาะวิวาท", key: "doer_family_fight" },
        { name: "การใช้ความรุนแรงในครอบครัว", key: "doer_family_violence" },
      ],
      doerEconomicList: [
        { name: "ว่างงาน", key: "doer_economic_unemployed" },
        { name: "หนี้สิน", key: "doer_economic_debt" },
        { name: "ยากจน", key: "doer_economic_poor" },
        { name: "การพนัน", key: "doer_economic_gambling" },
      ],
      doerHealthList: [
        { name: "เจ็บป่วยเรื้อรัง", key: "doer_health_sick" },
        { name: "พิการ", key: "doer_health_disabled" },
      ],
      doerMentalList: [
        { name: "ป่วยเป็นโรคจิตเวช", key: "doer_mental_illness" },
        { name: "ปัญหาทางพฤติกรรม", key: "doer_mental_behavior" },
        { name: "ปัญหาด้านอารมณ์/จิตใจ", key: "doer_mental_mood_mind" },
      ],
      violenceType: [
        { name: "ใช้กำลัง", key: "power_hurt" },
        { name: "ใช้อาวุธ", key: "weapon_hurt" },
        { name: "มอมยา", key: "drug_hurt" },
        { name: "มอมเหล้า", key: "alcohal_hurt" },
      ],
    };
  },
};
</script>

<style>
</style>