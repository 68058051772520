<template>
  <table class="table table-striped">
    <tr
      :class="isAllTheme ? 'bg-dark text-white' : 'bg-green-2-theme text-white'"
    >
      <th style="width: 900px" scope="col">
        4. สาเหตุการตั้งครรภ์ไม่พึงประสงค์
      </th>
      <th scope="col">จำนวน (ราย)</th>
    </tr>
    <tbody>
      <tr>
        <th scope="col">4.1 สาเหตุของการตั้งครรภ์ไม่พึงประสงค์</th>
        <th></th>
      </tr>
      <tr>
        <td>{{ model.pregnantCause[0].name }}</td>
        <td>{{ model.pregnantCause[0].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.problemsContraceptiveList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.pregnantCause[1].name }}</td>
        <td>{{ model.pregnantCause[1].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.problemsBreakLawList" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.pregnantCause[2].name }}</td>
        <td>{{ model.pregnantCause[2].value }}</td>
      </tr>
      <tr>
        <td>{{ model.pregnantCause[3].name }}</td>
        <td>{{ model.pregnantCause[3].value }}</td>
      </tr>
      <tr>
        <td>{{ model.pregnantCause[4].name }}</td>
        <td>{{ model.pregnantCause[4].value }}</td>
      </tr>
      <tr>
        <th scope="col">4.2 ปัจจัยประกอบการยุติการตั้งครรภ์</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.abortion" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["model", "isAllTheme"],
  data() {
    return {
      pregnantCause: [
        { name: "ปัญหาจากการคุมกำเนิด", key: "problems_contraceptive" },
        {
          name: "ปัญหาการตั้งครรภ์ที่เกิดจากการกระทำความผิดทางกฎหมาย",
          key: "problems_break_law",
        },
        { name: "ปัญหาทางด้านเศรษฐกิจ/สังคม", key: "social_economic_problem" },
        { name: "ปัญหาสัมพันธภาพภายในครอบครัว", key: "family_problem" },
        {
          name: "ปัญหาทางด้านการแพทย์ ตามมาตรา305(1)(2)",
          key: "medical_problem_305",
        },
      ],
      isBirthControl: [
        { name: "คุมกำเนิด", value: true, grid: "col-6" },
        { name: "ไม่ได้คุมกำเนิด", value: false, grid: "col-6" },
      ],
      problemsContraceptiveList: [
        { name: "ยาเม็ดคุมกำเนิดฉุกเฉิน", key: "birth_control_pill_emer" },
        { name: "ยาเม็ดคุมกำเนิด", key: "birth_control_pill" },
        { name: "ยาฉีดคุมกำเนิด", key: "birth_control_inject" },
        { name: "ยาฝังคุมกำเนิด", key: "birth_control_implant" },
        { name: "ถุงยางอนามัย", key: "birth_control_condom" },
        { name: "ใส่ห่วงอนามัย", key: "birth_control_iud" },
        { name: "ทำหมัน", key: "birth_control_sterilization" },
        { name: "หลั่งข้างนอก", key: "birth_control_out_ejaculation" },
      ],
      problemsBreakLawList: [
        {
          name: "กระทำชำเราเด็กอายุต่ำกว่า 15 ปี",
          key: "offend_under_fifteen",
        },
        {
          name: "กระทำชำเราบุคคลอายุ 15 ปีขึ้นไปแต่ไม่ถึง 18 ปีบริบูรณ์",
          key: "offend_over_fifteen_under_eighteen",
        },
        {
          name: "กระทำชำเราบุคคลอายุ 18 ปีขึ้นไป.",
          key: "offend_over_eighteen",
        },
      ],
      abortion: [
        { name: "ปัญหาสุขภาพกาย/จิตแม่", key: "health_mental_mother" },
        { name: "สุขภาพเด็ก", key: "health_mental_child" },
        { name: "ความผิดทางเพศ", key: "anomaly_sex" },
        {
          name: "อายุครรภ์ต่ำกว่า 12 สัปดาห์",
          key: "gestational_age_under_twelve",
        },
        {
          name: "อายุครรภ์ 12 – 20 สัปดาห์ขึ้นไป ให้คำปรึกษา",
          key: "gestational_age_over_twelve",
        },
      ],
    };
  },
};
</script>

<style>
</style>
