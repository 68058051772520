<template>
    <div class="my-5">
        <h4 class="text-center">
            รายงานสรุปจำนวนข้อมูลที่บันทึกเข้ามาในแต่ละข้อคำถามของแบบบันทึก
        </h4>
        <div class="container my-5">
            <div class="row mx-3 justify-content-center">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <label>เขตสุขภาพ</label>
                                    <b-form-select
                                            @change="setProvince($event)"
                                            v-model="formData.area_code"
                                            class="form-control"
                                    >
                                        <b-form-select-option value=""
                                        >เลือกเขตสุขภาพทั้งหมด
                                        </b-form-select-option
                                        >

                                        <b-form-select-option
                                                v-for="(item, i) in areaCode"
                                                :key="i"
                                                :value="item"
                                        >{{ `เขตสุขภาพที่ ${item}` }}
                                        </b-form-select-option
                                        >
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <label>จังหวัด</label>
                                    <b-form-select
                                            :disabled="!formData.area_code"
                                            @change="setHospital"
                                            v-model="formData.province"
                                            class="form-control"
                                    >
                                        <b-form-select-option value=""
                                        >เลือกจังหวัดทั้งหมด
                                        </b-form-select-option
                                        >

                                        <b-form-select-option
                                                v-for="(item, i) in province"
                                                :key="i"
                                                :value="item.code"
                                        >{{ item.name }}
                                        </b-form-select-option
                                        >
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <label>ประเภทของโรงพยาบาล</label>
                                    <b-form-select
                                            v-if="formData.area_code !== 13"
                                            @change="setHospital"
                                            v-model="formData.type"
                                            class="form-control"
                                    >
                                        <b-form-select-option value=""
                                        >เลือกประเภททั้งหมดของโรงพยาบาล
                                        </b-form-select-option
                                        >

                                        <b-form-select-option
                                                v-for="(item, i) in hospitalType"
                                                :key="i"
                                                :value="item"
                                        >{{ item.key }}
                                        </b-form-select-option
                                        >
                                    </b-form-select>

                                    <b-form-select
                                            v-else
                                            @change="setHospital"
                                            v-model="formData.type"
                                            class="form-control"
                                    >
                                        <b-form-select-option value=""
                                        >เลือกประเภททั้งหมดของโรงพยาบาล
                                        </b-form-select-option
                                        >

                                        <b-form-select-option
                                                v-for="(item, i) in thirteenType"
                                                :key="i"
                                                :value="item"
                                        >{{ item.key }}
                                        </b-form-select-option
                                        >
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <label>โรงพยาบาล</label>
                                    <b-form-select
                                            :disabled="
                      !formData.area_code &&
                      !formData.province &&
                      !formData.type
                    "
                                            v-model="formData.hospcode"
                                            class="form-control"
                                    >
                                        <b-form-select-option value=""
                                        >เลือกโรงพยาบาลทั้งหมด
                                        </b-form-select-option
                                        >

                                        <b-form-select-option
                                                v-for="(item, i) in hospcode"
                                                :key="i"
                                                :value="item.hospcode"
                                        >{{ item.name }}
                                        </b-form-select-option
                                        >
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                        <b-form-group v-slot="{ ariaDescribedby }">
                            <b-form-radio-group
                                    v-model="dateSelected"
                                    :aria-describedby="ariaDescribedby"
                            >
                                <div class="row violence-radio px-3">
                                    <div class="col-6 py-4 bg-grey-2-theme">
                                        <label>วันที่เกิดเหตุ</label>
                                        <div class="row align-items-center">
                                            <div class="col-1">
                                                <b-form-radio value="event"></b-form-radio>
                                            </div>
                                            <div class="col-5">
                                                <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                outlined
                                                                rounded
                                                                class="mt-2"
                                                                dense
                                                                v-model="computedStartEventDateFormatted"
                                                                append-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                            v-model="date.startEventDate"
                                                            @input="menu = false"
                                                            locale="th-th"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                            <div class="col-1">-</div>
                                            <div class="col-5">
                                                <v-menu
                                                        v-model="menu2"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                outlined
                                                                rounded
                                                                class="mt-2"
                                                                dense
                                                                v-model="computedEndEventDateFormatted"
                                                                append-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                            v-model="date.endEventDate"
                                                            @input="menu2 = false"
                                                            locale="th-th"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 py-4 bg-grey-2-theme">
                                        <label>วันที่มาโรงพยาบาล</label>
                                        <div class="row align-items-center">
                                            <div class="col-1">
                                                <b-form-radio value="hospital"></b-form-radio>
                                            </div>
                                            <div class="col-5">
                                                <v-menu
                                                        v-model="menu3"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                outlined
                                                                rounded
                                                                class="mt-2"
                                                                dense
                                                                v-model="computedStartHospitalDateFormatted"
                                                                append-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                            v-model="date.startHospitalDate"
                                                            @input="menu3 = false"
                                                            locale="th-th"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                            <div class="col-1">-</div>
                                            <div class="col-5">
                                                <v-menu
                                                        v-model="menu4"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                outlined
                                                                rounded
                                                                class="mt-2"
                                                                dense
                                                                v-model="computedEndHospitalDateFormatted"
                                                                append-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                            v-model="date.endHospitalDate"
                                                            @input="menu4 = false"
                                                            locale="th-th"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-form-radio-group>
                        </b-form-group>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    รายงานข้อมูล
                                    <small class="text-danger">*เลือกได้มากกว่า 1 รายงาน</small>
                                </div>
                                <div class="col-6">
                                    <div class="checkbox-border px-2 py-2 my-2">
                                        <b-form-checkbox
                                                v-model="formData.is_violence"
                                                :unchecked-value="false"
                                                @change="setType($event,'selectedViolence')"
                                                disabled-field="true"
                                        >
                                            ผู้ถูกกระทำรุนแรง
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="checkbox-border px-2 py-2 my-2">
                                        <b-form-checkbox
                                                v-model="formData.is_pregnant"
                                                @change="setType($event,'selectedViolence')"
                                                :unchecked-value="false"
                                                disabled-field="true"
                                        >
                                            ผู้ที่ตั้งครรภ์ไม่พึงประสงค์
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-10">
                                <div class="col-12">
                                    ส่วนที่ต้องการแสดง
                                    <small class="text-danger">*เลือกได้มากกว่า 1 ส่วน</small>
                                </div>
                                <div class="col-6">
                                    <div v-if="formData.is_violence">
                                        <div
                                                v-for="(item, index) in violenceList"
                                                :key="index"
                                                class="checkbox-border px-2 py-2 my-2"
                                        >
                                            <b-form-checkbox
                                                    @change="setData($event, item, 'selectedViolence')"
                                                    :unchecked-value="false"
                                                    disabled-field="true"
                                            >
                                                {{ `ข้อมูลผู้ถูกกระทำรุนแรง ส่วนที่ ${item}` }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div v-if="formData.is_pregnant">
                                        <div
                                                v-for="(item, index) in pregnantList"
                                                :key="index"
                                                class="checkbox-border px-2 py-2 my-2"
                                        >
                                            <b-form-checkbox
                                                    @change="setData($event, item, 'selectedPregnant')"
                                                    :unchecked-value="false"
                                                    disabled-field="true"
                                            >
                                                {{
                                                `ข้อมูลผู้ที่ตั้งครรภ์ไม่พึงประสงค์ ส่วนที่ ${item}`
                                                }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                    v-for="item in rangeAge"
                                    :key="item.id"
                                    class="row align-items-center my-5"
                            >
                                <div class="col-2">
                                    {{ item.minLabel ? item.minLabel : "อายุ" }}
                                </div>
                                <div class="col-3">
                                    <input
                                            v-model="item.min"
                                            class="form-control"
                                            type="number"
                                            min="0"
                                    />
                                </div>
                                <div class="col-1">ปี {{ item.minYearLabel }}</div>
                                <div v-if="item.max !== undefined" class="col-2">ไม่เกิน</div>
                                <div v-if="item.max !== undefined" class="col-3">
                                    <input
                                            v-model="item.max"
                                            class="form-control"
                                            type="number"
                                            min="0"
                                    />
                                </div>
                                <div v-if="item.max !== undefined" class="col-1">ปี</div>
                            </div>
                            <div class="row justify-content-center mt-10">
                                <div class="col-8">
                                    <button
                                            :disabled="selectFormYet"
                                            v-if="formData.is_violence && !formData.is_pregnant"
                                            @click="show"
                                            class="btn btn-block btn-border btn-page-violence"
                                    >
                                        แสดง
                                    </button>
                                    <button
                                            :disabled="selectFormYet"
                                            v-if="!formData.is_violence && formData.is_pregnant"
                                            @click="show"
                                            class="btn btn-block btn-border text-white bg-green-2-theme"
                                    >
                                        แสดง
                                    </button>
                                    <button
                                            :disabled="selectFormYet"
                                            v-if="formData.is_violence && formData.is_pregnant"
                                            @click="show"
                                            class="btn btn-block btn-border text-white bg-dark"
                                    >
                                        แสดง
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row justify-content-end mt-10">
                            <div class="col-3">
                                <button
                                        :disabled="selectFormYet"
                                        @click="exportExcel"
                                        class="btn btn-block btn-next-outline"
                                >
                                    ส่งออก Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                        v-if="formData.is_violence && !formData.is_pregnant && violence"
                        class="col-12"
                >
                    <Violenec
                            :count="count"
                            :info="info"
                            :ageRange="ageRange"
                            :formModel="violence"
                    ></Violenec>
                </div>
                <div
                        v-if="!formData.is_violence && formData.is_pregnant && pregnant"
                        class="col-12"
                >
                    <Pregnant
                            :count="count"
                            :ageRange="ageRange"
                            :info="info"
                            :formModel="pregnant"
                    ></Pregnant>
                </div>
                <div
                        v-if="
            formData.is_violence && formData.is_pregnant && violence && pregnant
          "
                        class="col-12"
                >
                    <All
                            :count="count"
                            :ageRange="ageRange"
                            :info="info"
                            :violenceModel="violence"
                            :pregnantModel="pregnant"
                    ></All>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import Violenec from "../views/ReportSummaryQuestion/RSQViolence.vue";
import Pregnant from "../views/ReportSummaryQuestion/RSQPregnant.vue";
import All from "../views/ReportSummaryQuestion/RSQAll.vue";

export default {
    components: {
        Violenec,
        Pregnant,
        All,
    },
    computed: {
        selectFormYet() {
            return (
                this.selectedViolence.length === 0 && this.selectedPregnant.length === 0
            );
        },
        computedStartEventDateFormatted() {
            if (!this.date.startEventDate) return null;
            const [year, month, day] = this.date.startEventDate.split("-");
            return `${day}/${month}/${parseInt(year) + 543}`;
        },
        computedEndEventDateFormatted() {
            if (!this.date.endEventDate) return null;
            const [year, month, day] = this.date.endEventDate.split("-");
            return `${day}/${month}/${parseInt(year) + 543}`;
        },
        computedStartHospitalDateFormatted() {
            if (!this.date.startHospitalDate) return null;
            const [year, month, day] = this.date.startHospitalDate.split("-");
            return `${day}/${month}/${parseInt(year) + 543}`;
        },
        computedEndHospitalDateFormatted() {
            if (!this.date.endHospitalDate) return null;
            const [year, month, day] = this.date.endHospitalDate.split("-");
            return `${day}/${month}/${parseInt(year) + 543}`;
        },

        ...mapState({
            province: (state) => {
                return state.MasterData.province;
            },
            ageRange: (state) => {
                return state.ReportSummary.age_range;
            },
            info: (state) => {
                return state.ReportSummary.info;
            },
            violence: (state) => {
                return state.ReportSummary.violence;
            },
            hospcode: (state) => {
                return state.MasterData.hospcode;
            },
            pregnant: (state) => {
                return state.ReportSummary.pregnant;
            },
            count: (state) => {
                return state.ReportSummary.count;
            },
        }),
    },
    data() {
        return {
            menu: false,
            menu2: false,
            menu3: false,
            menu4: false,
            rangeAge: [
                {id: 1, minLabel: "อายุไม่เกิน", min: 0, value: 0},
                {id: 2, min: 0, max: 0, value: 0},
                {id: 3, min: 0, max: 0, value: 0},
                {id: 4, min: 0, max: 0, value: 0},
                {id: 5, min: 0, max: 0, value: 0},
                {id: 6, min: 0, max: 0, value: 0},
                {id: 7, min: 0, max: 0, value: 0},
                {id: 8, minYearLabel: "ขึ้นไป", min: 0, value: 0},
            ],
            areaCode: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            hospitalType: [
                {key: "โรงพยาบาลศูนย์_A", value: "A"},
                {key: "โรงพยาบาลทั่วไป_S", value: "S"},
                {key: "โรงพยาบาลทั่วไปขนาดเล็ก_M1", value: "M1"},
                {key: "โรงพยาบาลชุมชนแม่ข่าย_M2", value: "M2"},
                {key: "โรงพยาบาลชุมชนขนาดใหญ่_F1", value: "F1"},
                {key: "โรงพยาบาลชุมชนขนาดกลาง_F2", value: "F2"},
                {key: "โรงพยาบาลชุมชนขนาดเล็ก_F3", value: "F3"},
            ],
            thirteenType: [
                {key: "สำนักงานสาธารณสุขจังหวัด", value: "สำนักงานสาธารณสุขจังหวัด"},
                {key: "สำนักงานสาธารณสุขอำเภอ", value: "สำนักงานสาธารณสุขอำเภอ"},
                {key: "สถานีอนามัย", value: "สถานีอนามัย"},
                {key: "สถานบริการสาธารณสุขชุมชน", value: "สถานบริการสาธารณสุขชุมชน"},
                {key: "โรงพยาบาลศูนย์", value: "โรงพยาบาลศูนย์"},
                {key: "โรงพยาบาลทั่วไป", value: "โรงพยาบาลทั่วไป"},
                {key: "โรงพยาบาลชุมชน", value: "โรงพยาบาลชุมชน"},
                {key: "โรงพยาบาลนอก สป.สธ.", value: "โรงพยาบาลนอก สป.สธ."},
            ],
            formData: {
                type: "",
                code: "",
                province: "",
                area_code: "",
                hospcode: "",
                is_violence: false,
                is_pregnant: false,
            },
            dateSelected: "event",
            violenceList: [1, 2, 3, 5],
            pregnantList: [1, 2, 3],
            selectedViolence: [],
            selectedPregnant: [],
            date: {
                startEventDate: "",
                endEventDate: "",
                startHospitalDate: "",
                endHospitalDate: "",
            },
        };
    },
    created() {
        this.$store.dispatch("ReportSummary/clear");
    },
    methods: {
        setType(event, type) {
            if (!event) {
                this[type] = []
            }
        },
        initDate(prop) {
            if (!this.date[prop]) {
                this.convertDate(prop);
            }
        },
        exportExcel() {
            Swal.fire({
                title: "กำลังแสดงข้อมูล",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading();
                    await this.$store
                        .dispatch("Form/exportReportSummary", {
                            is_export: true,
                            age_range: this.rangeAge,
                            area_code: this.formData.area_code,
                            type: this.formData.type.key,
                            hospcode: this.formData.hospcode,
                            violence: this.selectedViolence,
                            pregnant: this.selectedPregnant,
                            start_event_date:
                                this.dateSelected === "event"
                                    ? this.formatDate(this.date.startEventDate)
                                    : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                            end_event_date:
                                this.dateSelected === "event"
                                    ? this.formatDate(this.date.endEventDate)
                                    : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                            start_hospital_date:
                                this.dateSelected === "hospital"
                                    ? this.formatDate(this.date.startHospitalDate)
                                    : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                            end_hospital_date:
                                this.dateSelected === "hospital"
                                    ? this.formatDate(this.date.endHospitalDate)
                                    : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                        })
                        .then((response) => {
                            window.open(response);
                            Swal.close();
                        })
                        .catch(() => {
                            Swal.close();
                        });
                    Swal.close();
                },
            });
        },
        setProvince(event) {
            if (event)
                this.$store.dispatch("MasterData/setProvinceByAreaCode", event);
            this.$store.dispatch("MasterData/setHospCode", {
                type: this.formData.type.value,
                province: this.formData.province,
                code: this.formData.area_code,
            });
        },
        setHospital() {
            this.$store.dispatch("MasterData/setHospCode", {
                type: this.formData.type.value,
                province: this.formData.province,
                code: this.formData.area_code,
            });
        },
        convertDate(name) {
            let christianYear = moment().format("YYYY");
            let buddhishYear = (parseInt(christianYear) + 543).toString();
            let thaiDate = moment()
                .format(
                    "DD-MM-YYYY"
                        .replace("YYYY", buddhishYear)
                        .replace("YY", buddhishYear.substring(2, 4))
                )
                .replace(christianYear, buddhishYear);
            this.date[name] = thaiDate;
        },
        setData(event, item, data) {
            console.log(event, item, data);
            if (event) {
                this[data].push(item);
            } else {
                let index = this[data].indexOf(item);
                if (index !== -1) {
                    this[data].splice(index, 1);
                }
            }
        },
        formatDate(date) {
            return date
                ? moment(date, "YYYY-MM-DD").add(543, "years").format("YYYY-MM-DD")
                : "";
        },
        show() {
            Swal.fire({
                title: "กำลังแสดงข้อมูล",
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: async () => {
                    Swal.showLoading();
                    await this.$store.dispatch("ReportSummary/show", {
                        age_range: this.rangeAge,
                        area_code: this.formData.area_code,
                        type: this.formData.type.key,
                        hospcode: this.formData.hospcode,
                        violence: this.selectedViolence,
                        pregnant: this.selectedPregnant,
                        start_event_date:
                            this.dateSelected === "event"
                                ? this.formatDate(this.date.startEventDate)
                                : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                        end_event_date:
                            this.dateSelected === "event"
                                ? this.formatDate(this.date.endEventDate)
                                : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                        start_hospital_date:
                            this.dateSelected === "hospital"
                                ? this.formatDate(this.date.startHospitalDate)
                                : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                        end_hospital_date:
                            this.dateSelected === "hospital"
                                ? this.formatDate(this.date.endHospitalDate)
                                : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    });
                    Swal.close();
                },
            });
        },
    },
};
</script>

<style>
</style>