<template>
  <div class="row">
    <div class="col-12 mt-10">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td class="w-25 text-white bg-green-2-theme">เขตที่</td>
            <td>{{ info.area_code }}</td>
          </tr>
          <tr>
            <td class="w-25 text-white bg-green-2-theme">จังหวัด</td>
            <td>{{ info.province }}</td>
          </tr>
          <tr>
            <td class="w-25 text-white bg-green-2-theme">ประเภทโรงพยาบาล</td>
            <td>{{ info.type }}</td>
          </tr>
          <tr>
            <td class="w-25 text-white bg-green-2-theme">ชื่อโรงพยาบาล</td>
            <td>{{ info.hospital }}</td>
          </tr>
          <tr>
            <td class="w-25 text-white bg-green-2-theme">วันที่เกิดเหตุ</td>
            <td>
              {{
                `${convertDate(info.start_event_date)} - ${convertDate(
                  info.end_event_date
                )}`
              }}
            </td>
          </tr>
          <tr>
            <td class="w-25 text-white bg-green-2-theme">วันที่มาโรงพยาบาล</td>
            <td>
              {{
                `${convertDate(info.start_hospital_date)} - ${convertDate(
                  info.end_hospital_date
                )}`
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 mt-10">
      <table class="table table-striped">
        <tr class="bg-green-2-theme text-white">
          <th scope="col">ทั้งหมด</th>
          <th scope="col">{{ count }}</th>
          <th scope="col">ราย</th>
        </tr>
      </table>
      <!-- <Form1></Form1> -->
      <Form1
        :ageRange="ageRange"
        :model="formModel.form1"
        v-if="formModel.form1"
      ></Form1>
      <Form2 v-if="formModel.form2" :model="formModel.form2"></Form2>
      <Form3 v-if="formModel.form3" :model="formModel.form3"></Form3>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
import moment from "moment";
import Form1 from "../../components/reportSummaryQuestion/pregnant/RSQPregnantForm1.vue";
import Form2 from "../../components/reportSummaryQuestion/pregnant/RSQPregnantForm2.vue";
import Form3 from "../../components/reportSummaryQuestion/pregnant/RSQPregnantForm3.vue";
export default {
  props: ["formModel", "ageRange", "info", "count"],
  components: {
    Form1,
    Form2,
    Form3,
  },
  methods: {
    convertDate(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      rangeAge: [
        { id: 1, minLabel: "อายุไม่เกิน", min: 0 },
        { id: 2, min: 0, max: 0 },
        { id: 3, min: 0, max: 0 },
        { id: 4, min: 0, max: 0 },
        { id: 5, min: 0, max: 0 },
        { id: 6, min: 0, max: 0 },
        { id: 7, min: 0, max: 0 },
        { id: 8, minYearLabel: "ขึ้นไป", min: 0 },
      ],
      formData: {
        province: "",
      },
      dateSelected: "event",
      date: {
        startEventDate: "",
        endEventDate: "",
        startHospitalDate: "",
        endHospitalDate: "",
      },
    };
  },
};
</script>

<style>
</style>