<template>
  <table class="table table-striped">
    <tr
      :class="
        isAllTheme ? 'bg-dark text-white' : 'bg-purple-2-theme text-white'
      "
    >
      <th style="width: 900px" scope="col">
        4. รายละเอียดเกี่ยวกับการละเมิดทางเพศ
      </th>
      <th scope="col">จำนวน (ราย)</th>
    </tr>
    <tbody>
      <tr>
        <th scope="col">4.1 รายละเอียดการละเมิดทางเพศ</th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">
          4.1.1 การใช้อวัยวะเพศของผู้กระทำสอดใส่กับอวัยวะของผู้เสียหาย
        </th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.genital_insertion" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.part" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">4.1.2 มีการใช้สิ่งอื่น กระทำกับอวัยวะของผู้เสียหาย</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.other_genital_insertion" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.part" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">4.1.3 การหลั่งอสุจิ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.ejaculation" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">4.1.4 การสวมถุงยาง</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.condom" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">4.1.5 จำนวนผู้กระทำ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.victimAmout" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">4.2 การเก็บวัตถุพยาน</th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">
          4.2.1 เสื้อผ้าที่สวมใส่อยู่ปัจจุบัน
          เป็นเสื้อผ้าที่สวมใส่ในช่วงเหตุการณ์
        </th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.same_clothes_event" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">
          4.2.2 กางเกงในที่สวมใส่อยู่ในปัจจุบัน
          เป็นเสื้อผ้าที่สวมใส่ในช่วงเหตุการณ์
        </th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.same_underwear_event" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">
          4.2.3 หลังจากเหตุการณ์
          ได้ทำความสะอาดร่างกายบริเวณที่ถูกกระทำชำเราหรือไม่
        </th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.cleanBody" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">
          4.2.4 ภายใน 2 วัน หลังจากเกิดเหตุการณ์ ได้มีเพศสัมพันธ์หรือไม่
        </th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.have_sex_after_event" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["model", "isAllTheme"],
  data() {
    return {
      cleanBody: [
        {
          name: "ล้างภายนอก / สวนล้าง (อวัยวะเพศ / ทวารหนัก)",
          key: "other_obj_victim_genitalia",
        },
        { name: "ถ่ายอุจาระ", key: "defecate" },
        { name: "บ้วนปาก / แปรงฟัน", key: "oral_cleaning" },
      ],
      know_doer_amount: [
        { name: "รู้จำนวนที่แน่นอน", grid: "col-12" },
        { name: "ไม่ค่อยแน่ใจ แต่ค่อนข้างเชื่อว่ามี", grid: "col-12" },
        { name: "ไม่ทราบ", grid: "col-12" },
      ],
      part: [
        { name: "อวัยวะเพศ", key: "victim_genitalia" },
        { name: "ทวารหนัก", key: "victim_anus" },
        { name: "ช่องปาก", key: "victim_mouth" },
      ],
      isBool: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      have_sex_after_event: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      other_genital_insertion: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      genital_insertion: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      same_underwear_event: [
        { name: "ใช่", value: true, grid: "col-2" },
        { name: "ไม่ใช่", value: false, grid: "col-2" },
      ],
      ejaculation: [
        { name: "มี", grid: "col-12" },
        { name: "ไม่มี", grid: "col-12" },
        { name: "ไม่ทราบ", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่ามี", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่าไม่มี", grid: "col-12" },
      ],
      condom: [
        { name: "สวม", grid: "col-12" },
        { name: "ไม่สวม", grid: "col-12" },
        { name: "ไม่ทราบ", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่าสวม", grid: "col-12" },
        { name: "ไม่แน่ใจ แต่ค่อนข้างเชื่อว่าไม่สวม", grid: "col-12" },
      ],
    };
  },
};
</script>

<style>
</style>