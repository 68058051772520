<template>
  <table class="table table-striped">
    <tr
      :class="isAllTheme ? 'bg-dark text-white' : 'bg-green-2-theme text-white'"
    >
      <th style="width: 900px" scope="col">5. การให้คำปรึกษาทางเลือก</th>
      <th scope="col"></th>
    </tr>
    <tbody>
      <tr>
        <th scope="col">5.1 การให้บริการ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.service" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr
        :class="
          isAllTheme ? 'bg-dark text-white' : 'bg-green-2-theme text-white'
        "
      >
        <th scope="col">6. ผลการตัดสินใจหลังให้การปรึกษาทางเลือก</th>
        <th scope="col"></th>
      </tr>
      <tr>
        <th scope="col">6.1 ตั้งครรภ์ต่อ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.pregnant" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">6.2 ยุติการตั้งครรภ์</th>
        <th></th>
      </tr>
      <tr>
        <td>{{ model.abortion[0].name }}</td>
        <td>{{ model.abortion[0].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.abortion_process" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.abortion[1].name }}</td>
        <td>{{ model.abortion[1].value }}</td>
      </tr>
      <tr>
        <td>{{ model.abortion[2].name }}</td>
        <td>{{ model.abortion[2].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.contraceptive_process" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr
        :class="
          isAllTheme ? 'bg-dark text-white' : 'bg-green-2-theme text-white'
        "
      >
        <th scope="col">7 สถานะการให้บริการ</th>
        <th scope="col"></th>
      </tr>
      <tr v-for="(item, index) in model.service_status" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr v-for="(item, index) in model.service_status_denied" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["model", "isAllTheme"],
  data() {
    return {
      service: [
        { name: "ให้คำปรึกษาอายุครรภ์ต่ำกว่า 12 สัปดาห์", grid: "col-12" },
        { name: "ให้คำปรึกษาอายุครรภ์ 12 - 20 สัปดาห์", grid: "col-12" },
        { name: "ให้คำปรึกษาอายุครรภ์ 20 สัปดาห์ขึ้นไป", grid: "col-12" },
      ],
      pregnant: [{ name: "ฝากครรภ์", key: "antenatal_care" }],
      abortion: [
        { name: "ยุติการตั้งครรภ์ในโรงพยาบาล", grid: "col-12" },
        { name: "ส่งต่อ/แนะนำเครือข่ายยุติการตั้งครรภ์", grid: "col-12" },
        { name: "คุมกำเนิด", grid: "col-12" },
      ],
      abortion_process: [
        { name: "ยุติการตั้งครรภ์ด้วยยา", grid: "col-12" },
        { name: "ยุติการตั้งครรภ์ด้วยหัตการ", grid: "col-12" },
      ],
      contraceptive_process: [
        { name: "ฝั่งยาคุม", grid: "col-12" },
        { name: "ใส่ห่วง", grid: "col-12" },
        { name: "ฉีด", grid: "col-12" },
        { name: "ทำหมัน", grid: "col-12" },
      ],
      service_status: [
        { name: "อยู่ระหว่างดำเนินการ", grid: "col-12" },
        { name: "สิ้นสุดการให้บริการ", grid: "col-12" },
      ],
      service_status_denied: [
        { name: "ให้บริการครบตามที่วางแผน", grid: "col-12" },
        { name: "ปฏิเสธการรับบริการ", grid: "col-12" },
        { name: "เสียชีวิต", grid: "col-12" },
        { name: "ติดตามไม่ได้", grid: "col-12" },
        { name: "ส่งต่อไปรักษายังหน่วยงานอื่น ๆ", grid: "col-12" },
      ],
    };
  },
};
</script>

<style>
</style>