<template>
  <div class="row">
    <div class="col-12 mt-10">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td class="w-25 bg-dark text-white bg-purple-2-theme">เขตที่</td>
            <td></td>
          </tr>
          <tr>
            <td class="w-25 bg-dark text-white bg-purple-2-theme">จังหวัด</td>
            <td></td>
          </tr>
          <tr>
            <td class="w-25 bg-dark text-white bg-purple-2-theme">
              ประเภทโรงพยาบาล
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="w-25 bg-dark text-white bg-purple-2-theme">
              ชื่อโรงพยาบาล
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="w-25 bg-dark text-white bg-purple-2-theme">
              วันที่เกิดเหตุ
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="w-25 bg-dark text-white bg-purple-2-theme">
              วันที่มาโรงพยาบาล
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 mt-10">
      <table class="table table-striped">
        <tr class="bg-dark text-white">
          <th scope="col">ทั้งหมด</th>
          <th scope="col">{{ count }}</th>
          <th scope="col">ราย</th>
        </tr>
      </table>
      <Violence1
        :ageRange="ageRange"
        :isAllTheme="true"
        :count="count"
        :model="violenceModel.form1"
        v-if="violenceModel.form1"
      ></Violence1>
      <Violence2 :isAllTheme="true" :model="violenceModel.form2" v-if="violenceModel.form2"></Violence2>
      <Violence3 :isAllTheme="true" :model="violenceModel.form3" v-if="violenceModel.form3"></Violence3>
      <Violence5 :isAllTheme="true" :model="violenceModel.form5" v-if="violenceModel.form5"></Violence5>
      <Pregnant1
        :isAllTheme="true"
        :ageRange="ageRange"
        :count="count"
        :model="pregnantModel.form1"
        v-if="pregnantModel.form1"
      ></Pregnant1>
      <Pregnant2 v-if="pregnantModel.form2" :isAllTheme="true" :model="pregnantModel.form2"></Pregnant2>
      <Pregnant3 v-if="pregnantModel.form3" :isAllTheme="true" :model="pregnantModel.form3"></Pregnant3>
    </div>
  </div>
</template>

<script>
import Violence1 from "../../components/reportSummaryQuestion/violence/RSQViolenceForm1.vue";
import Violence2 from "../../components/reportSummaryQuestion/violence/RSQViolenceForm2.vue";
import Violence3 from "../../components/reportSummaryQuestion/violence/RSQViolenceForm3.vue";
import Violence5 from "../../components/reportSummaryQuestion/violence/RSQViolenceForm5.vue";
import Pregnant1 from "../../components/reportSummaryQuestion/pregnant/RSQPregnantForm1.vue";
import Pregnant2 from "../../components/reportSummaryQuestion/pregnant/RSQPregnantForm2.vue";
import Pregnant3 from "../../components/reportSummaryQuestion/pregnant/RSQPregnantForm3.vue";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
export default {
  props: ["count", "ageRange", "info", "violenceModel", "pregnantModel"],
  components: {
    Violence1,
    Violence2,
    Violence3,
    Violence5,
    Pregnant1,
    Pregnant2,
    Pregnant3,
  },
  data() {
    return {
      violenceList: [1, 2, 3, 4, 5],
      pregnantList: [1, 2, 3],
      rangeAge: [
        { id: 1, minLabel: "อายุไม่เกิน", min: 0 },
        { id: 2, min: 0, max: 0 },
        { id: 3, min: 0, max: 0 },
        { id: 4, min: 0, max: 0 },
        { id: 5, min: 0, max: 0 },
        { id: 6, min: 0, max: 0 },
        { id: 7, min: 0, max: 0 },
        { id: 8, minYearLabel: "ขึ้นไป", min: 0 },
      ],
      date: {
        startEventDate: "",
        endEventDate: "",
        startHospitalDate: "",
        endHospitalDate: "",
      },
    };
  },
};
</script>

<style>
</style>