<template>
  <table class="table table-striped">
    <tr
      :class="
        isAllTheme ? 'bg-dark text-white' : 'bg-purple-2-theme text-white'
      "
    >
      <th style="width: 900px" scope="col">6. บริการทางการแพทย์และสังคม</th>
      <th scope="col">จำนวน (ราย)</th>
    </tr>
    <tbody>
      <tr>
        <th scope="col">6.1 ด้านการแพทย์</th>
        <th></th>
      </tr>
      <tr>
        <td>{{ model.diagnosis[0].name }}</td>
        <td>{{ model.diagnosis[0].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.medicalDianogsis" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.diagnosis[1].name }}</td>
        <td>{{ model.diagnosis[1].value }}</td>
      </tr>
      <tr>
        <td>การประเมินพัฒนาการ</td>
        <td></td>
      </tr>
      <tr>
        <td>{{ model.diagnosis[2].name }}</td>
        <td>{{ model.diagnosis[2].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.medicalProcess" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.diagnosis[3].name }}</td>
        <td>{{ model.diagnosis[3].value }}</td>
      </tr>
      <tr>
        <td>{{ model.diagnosis[4].name }}</td>
        <td>{{ model.diagnosis[4].value }}</td>
      </tr>
      <tr>
        <td>{{ model.diagnosis[5].name }}</td>
        <td>{{ model.diagnosis[5].value }}</td>
      </tr>
      <tr>
        <td>{{ model.diagnosis[6].name }}</td>
        <td>{{ model.diagnosis[6].value }}</td>
      </tr>
      <tr>
        <td>{{ model.diagnosis[7].name }}</td>
        <td>{{ model.diagnosis[7].value }}</td>
      </tr>
      <tr>
        <th scope="col">6.2 ด้านสุขภาพจิตและจิตเวช</th>
        <th></th>
      </tr>
      <tr>
        <td>{{ model.therapy[0].name }}</td>
        <td>{{ model.therapy[0].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.assessment" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.therapy[1].name }}</td>
        <td>{{ model.therapy[1].value }}</td>
      </tr>
      <tr v-for="(item, index) in model.treatment" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <td>{{ model.therapy[2].name }}</td>
        <td>{{ model.therapy[2].value }}</td>
      </tr>
      <tr>
        <th scope="col">6.3 ด้านสังคม</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.social" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">7. การให้รหัสมาตรฐานทางการแพทย์และรหัสหัตถการ</th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">7.1 Principle Diagnosis</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.isBool" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">7.2 Comobidity</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.is_comorbidity" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">7.3 Complication</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.is_complication" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">7.4 Operation</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.is_operation" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">8. การดำเนินการทางกฏหมาย</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.law_operation" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">9. สถานะการให้บริการ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.service_status_denied" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["model", "isAllTheme"],
  data() {
    return {
      service_status_denied: [
        { name: "ให้บริการครบตามที่วางแผน", grid: "col-12" },
        { name: "ปฏิเสธการรับบริการ", grid: "col-12" },
        { name: "เสียชีวิต", grid: "col-12" },
        { name: "ติดตามไม่ได้", grid: "col-12" },
        { name: "ส่งต่อไปรักษายังหน่วยงานอื่น ๆ", grid: "col-12" },
      ],
      isBool: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],

      is_comorbidity: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],

      is_complication: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],

      law_operation: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      is_operation: [
        { name: "มี", value: true, grid: "col-2" },
        { name: "ไม่มี", value: false, grid: "col-2" },
      ],
      social: [
        { name: "ประเมินและวินิจฉัยทางสังคม", key: "diagnosis_social" },
        { name: "การบำบัดทางสังคม", key: "therapy_social" },
        { name: "ป้องกันปัญหาทางสังคม", key: "prevent_social_problem" },
        {
          name: "การฟื้นฟูสมรรถภาพทางสังคม",
          key: "social_recovery_performance",
        },
        {
          name: "การจัดการทรัพยากรทางสังคมและพิทักษ์สิทธิ์",
          key: "resource_social_management",
        },
        {
          name: "ติดตามผลประเมินคุณภาพชีวิต และติดตามป้องกันการเจ็บป่วยซ้ำผู้ป่วยหลังจำหน่าย",
          key: "follow_up_after_Illness",
        },
        { name: "ประสานทางกฎหมาย", key: "legal_cooperate" },
      ],
      assessment: [
        { name: "PTSD", key: "ptsd_test" },
        { name: "Psycho test", key: "psycho_test" },
        { name: "IQ Test", key: "iq_test" },
        {
          name: "การให้คำปรึกษาและสุขภาพจิตศึกษา",
          key: "consulting_mental_health",
        },
      ],
      therapy: [
        { name: "การประเมินและตรวจวินิจฉัย", key: "assessment_diagnosis" },
        { name: "บำบัดรักษา", key: "treatment" },
        { name: "การบริการนิติจิตเวช", key: "forensic_psychiatry" },
      ],
      treatment: [
        { name: "Counseling", key: "counseling" },
        { name: "Psycho Therapy", key: "psycho_therapy" },
        { name: "Family Therapy", key: "family_therapy" },
        { name: "บำบัดความคิดและพฤติกรรม (CBT)", key: "cbt_therapy" },
        { name: "บำบัดรักษาด้วยยา", key: "medicine_tharapy" },
      ],
      diagnosis: [
        {
          name: "การตรวจประเมินและวินิจฉัยทางการแพทย์",
          key: "medical_diagnosis",
        },
        {
          name: "การตรวจวินิจฉัยทางพยาธิวิทยาคลินิก",
          key: "pathology_diagnosis",
        },
        {
          name: "การรักษาและหัตถการ",
          key: "cure_procedure",
        },
        {
          name: "การบำบัดฟื้นฟูทางการแพทย์",
          key: "medical_recovery",
        },
        {
          name: "การติดตามการรักษา",
          key: "follow_up_treatment",
        },
        {
          name: "การส่งพบแพทย์เฉพาะทาง",
          key: "send_to_specialist",
        },
        {
          name: "การลงความเห็นในใบชันสูตร",
          key: "autopsy_opinion",
        },
        {
          name: "การตรวจประเมินอายุ*+ผลการประเมินอายุ..ปี",
          key: "estimate_age_result",
        },
      ],
      medicalProcess: [
        {
          name: "การดูแลรักษาการบาดเจ็บ",
          key: "cure_injury",
        },
        {
          name: "การได้รับยาป้องกันการตั้งครรภ์",
          key: "medicine_birth_control",
        },
        {
          name: "การได้รับยาป้องกันไวรัส เอชไอวี",
          key: "medicine_hiv",
        },
        {
          name: "การได้รับยาป้องกันการติดเชื้อทางเพศสัมพันธ์",
          key: "medicine_sex_infection",
        },
        {
          name: "การฝากครรภ์ต่อ",
          key: "antenatal_care",
        },
        {
          name: "การยุติการตั้งครรภ์",
          key: "abortion",
        },
        {
          name: "การคุมกำเนิด",
          key: "birth_control",
        },
      ],
      medicalDianogsis: [
        {
          name: "การตรวจลักษณะการบาดเจ็บกรณีถูกทำร้ายร่างกาย",
          key: "medical_diagnosis_violence",
        },
        {
          name: "การตรวจรักษาทางสูตินารีเวชกรรม",
          key: "medical_diagnosis_obstetrics",
        },
        {
          name: "การตรวจทางห้องปฏิบัติการ (LAB)",
          key: "medical_diagnosis_lab",
        },
      ],
    };
  },
};
</script>

<style>
</style>