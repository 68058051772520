<template>
  <table class="table table-striped">
    <tr
      :class="isAllTheme ? 'bg-dark text-white' : 'bg-green-2-theme text-white'"
    >
      <th style="width: 900px" scope="col">1. ข้อมูลผู้รับบริการ</th>
      <th scope="col">จำนวน (ราย)</th>
    </tr>
    <tbody>
      <tr>
        <th scope="col">2.1 ข้อมูลส่วนตัว</th>
        <th></th>
      </tr>
      <tr>
        <th scope="col">2.1.1 เพศ</th>
        <th></th>
      </tr>

      <tr v-for="(item, index) in model.sex" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>

      <tr>
        <th scope="col">2.1.2 ระดับอายุ (ปี)</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in ageRange" :key="index">
        <td v-if="item">{{ rangeText(item, index) }}</td>
        <td v-if="item">{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">2.1.3 สัญชาติ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.nationality" :key="index">
        <td>{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">2.1.4 ศาสนา</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.religion" :key="index">
        <td class="ml-5">{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">2.1.5 สถานะภาพสมรส</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.marriage_status" :key="index">
        <td class="ml-5">{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">2.1.6 สิทธิการรักษาพยาบาล</th>
        <th></th>
      </tr>
      <tr class="pl-5">
        <th scope="col">สิทธิติดตัว</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.welfare" :key="index">
        <td class="ml-5">{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr class="pl-5">
        <th scope="col">สิทธิใช้บริการครั้งนี้</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.welfare_now" :key="index">
        <td class="ml-5">{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr>
        <th scope="col">2.1.7 ระดับการศึกษา</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.education" :key="index">
        <td class="ml-5">{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr class="pl-5">
        <th scope="col">2.1.8 อาชีพ</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.occupation" :key="index">
        <td class="ml-5">{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
      <tr class="pl-5">
        <th scope="col">2.4 ความเกี่ยวข้องกับผู้นำส่งข้อมูล</th>
        <th></th>
      </tr>
      <tr v-for="(item, index) in model.reference_person_relation" :key="index">
        <td class="ml-5">{{ item.name }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["ageRange", "model", "isAllTheme"],
  methods: {
    rangeText(item, index) {
      if (index === 0) {
        return `ไม่เกิน ${item.min ? item.min : 0} ปี`;
      } else if (index === 7) {
        return `${item.min ? item.min : 0} ปีขึ้นไป`;
      } else {
        return `${item.min ? item.min : 0} ปี - ไม่เกิน ${
          item.max ? item.max : 0
        } ปี`;
      }
    },
  },
  data() {
    return {
      sex: [
        { name: "ชาย" },
        { name: "หญิง" },
        { name: "เพศทางเลือก" },
      ],
      marriage_status: [
        { name: "โสด" },
        { name: "สมรส (จดทะเบียน)" },
        { name: "สมรส (ไม่จดทะเบียน)" },
        { name: "หย่า" },
        { name: "หม้าย" },
      ],
      nationality: [
        { name: "ไทย" },
        { name: "เมียนมา" },
        { name: "ลาว" },
        { name: "กับพูชา" },
        { name: "มาเลเซีย" },
        { name: "เวียดนาม" },
        { name: "ไต้หวัน" },
        { name: "อื่นๆ" },
      ],
      religion: [
        { name: "พุทธ", grid: "col-2" },
        { name: "อิสลาม", grid: "col-2" },
        { name: "คริสต์", grid: "col-2" },
        { name: "ฮินดู", grid: "col-2" },
      ],
      welfare: [
        { name: "ข้าราชการ" },
        { name: "รัฐวิสาหกิจ" },
        { name: "ประกันสังคม" },
        { name: "ประกันสุขภาพเอกชน" },
        { name: "ประกันสุขถาพถ้วนหน้า" },
        { name: "แรงงานต่างด้าว" },
        { name: "อนุเคราะห์" },
        { name: "ชำระเงินเอง" },
        { name: "อื่นๆ" },
      ],
      welfare_now: [
        { name: "ข้าราชการ" },
        { name: "รัฐวิสาหกิจ" },
        { name: "ประกันสังคม" },
        { name: "ประกันสุขภาพเอกชน" },
        { name: "ประกันสุขถาพถ้วนหน้า" },
        { name: "แรงงานต่างด้าว" },
        { name: "อนุเคราะห์" },
        { name: "เบิกจากหน่วยงานส่งต่อ" },
        { name: "เบิกจากกองทุน" },
        { name: "ชำระเงินเอง" },
        { name: "อื่นๆ" },
      ],
      education: [
        { name: "อนุบาล" },
        { name: "ประถมศึกษา" },
        { name: "มัธยมศึกษาตอนต้น" },
        { name: "มัธยมศึกษาตอนปลาย" },
        { name: "อาชีวะ" },
        { name: "ปวช" },
        { name: "อนุปริญญา" },
        { name: "ปวส" },
        { name: "ปริญญาตรี" },
        { name: "สูงกว่าปริญญาตรี" },
        { name: "ไม่ได้รับการศึกษา" },
      ],
      occupation: [
        { name: "นักเรียน" },
        { name: "นักศึกษา" },
        { name: "ค้าขาย" },
        { name: "เกษตรกรรม" },
        { name: "ธุรกิจส่วนตัว" },
        { name: "ข้าราชการ" },
        { name: "รัฐวิสาหกิจ" },
        { name: "แม่บ้าน" },
        { name: "ว่างงาน" },
        { name: "พนักงานบริษัทเอกชน" },
        { name: "รับจ้าง" },
      ],
      reference_person_relation: [
        { name: "พ่อ" },
        { name: "แม่" },
        { name: "พี่น้อง" },
        { name: "ญาติ" },
        { name: "พ่อบุญธรรม" },
        { name: "แม่บุญธรรม" },
        { name: "พ่อเลี้ยง" },
        { name: "แม่เลี้ยง" },
        { name: "ลูกสาว" },
        { name: "ลูกชาย" },
        { name: "ลูกเลี้ยง" },
        { name: "ผู้ดูแล" },
        { name: "ผู้อุปการะ" },
        { name: "แฟน" },
        { name: "สามี" },
        { name: "ภรรยา" },
        { name: "คู่เพศทางเลือก" },
        { name: "เพื่อน" },
        { name: "เพื่อนร่วมงาน" },
        { name: "เพื่อนบ้าน" },
        { name: "ครู" },
        { name: "นายจ้าง" },
        { name: "ลูกจ้าง" },
        { name: "พระ" },
        { name: "นักบวช" },
        { name: "คนรู้จัก" },
        { name: "คนไม่รู้จัก" },
        { name: "คนแปลกหน้า" },
        { name: "หน่วยงานรัฐ" },
        { name: "หน่วยงานเอกชน+มูลนิธิ" },
      ],
    };
  },
};
</script>

<style>
table {
  margin-bottom: 0em !important;
}
</style>